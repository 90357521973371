<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<style lang="scss">
.blog-products-carousel {
    padding-top: 60px;

    .carousel__prev,
    .carousel__next {
        position: absolute;
        top: 0;
        transform: translateY(-50px);
    }
}
</style>
<template>
    <div id="main-container" class="">
        <div class="container" v-if="blog_post">
            <div class="row ">
                <div class="col-md-8  ">
                    <div class="blog-post-wrapper">
                        <div class="post-image"
                             :style="'background-image: url(/storage/'+ blog_post.picture +')'"></div>
                        <div class="blog-post-text">
                            <div class="blog-post-title">
                                {{ blog_post.title }}
                            </div>
                            <div class="subtitle d-flex">
                <span class="category me-2">
                  {{ blog_post.category?.title }}
                </span>
                                <span class="separator me-2">/</span>
                                <span class="date">
                   {{ blog_post.date }}
                </span>

                                <span class="ms-auto d-flex copy-link align-items-center">
                 <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M10.2514 7.96983C10.2514 9.20944 10.2514 10.449 10.2514 11.6887C10.2514 12.7948 9.50162 13.6787 8.36518 13.9122C8.22459 13.94 8.07814 13.9566 7.93169 13.9566C6.063 13.9566 4.18845 13.9622 2.31975 13.9566C1.15988 13.9566 0.175739 13.1284 0.0234319 12.0389C0.00585797 11.9277 0 11.8165 0 11.7053C0 9.21499 0 6.73022 0 4.23989C0 2.9836 1.03686 1.99414 2.36076 1.99414C4.20602 1.99414 6.05128 1.99414 7.9024 1.99414C9.2263 1.99414 10.2632 2.97804 10.2632 4.23433C10.2514 5.4795 10.2514 6.72466 10.2514 7.96983ZM9.07985 7.98095C9.07985 6.75246 9.07985 5.52397 9.07985 4.28992C9.07985 3.57283 8.58778 3.10034 7.82624 3.10034C6.02199 3.10034 4.21188 3.10034 2.40762 3.10034C1.65195 3.10034 1.15988 3.57283 1.15988 4.28992C1.15988 6.7469 1.15988 9.19832 1.15988 11.6553C1.15988 12.3724 1.65195 12.8449 2.41348 12.8449C4.21774 12.8449 6.02785 12.8449 7.8321 12.8449C8.58778 12.8449 9.08571 12.3779 9.08571 11.6553C9.07985 10.4324 9.07985 9.20388 9.07985 7.98095Z"
                      fill="black"/>
                  <path
                      d="M13 6.26924C13 7.6604 13 9.04607 13 10.4372C13 10.6673 12.8819 10.8425 12.6457 10.9411C12.4158 11.0397 12.192 11.0123 11.9931 10.8754C11.8253 10.7604 11.7569 10.607 11.7569 10.4208C11.7569 9.16657 11.7569 7.91782 11.7569 6.66359C11.7569 5.20123 11.7569 3.73888 11.7569 2.27652C11.7569 1.55904 11.2348 1.09897 10.4206 1.09897C8.16445 1.09897 5.91449 1.09897 3.65831 1.09897C3.39727 1.09897 3.19837 1.00586 3.07407 0.797739C2.95597 0.600568 2.98084 0.39792 3.13622 0.217179C3.25431 0.0747776 3.41591 0.0035768 3.6148 0.0035768C5.95799 0.0035768 8.29497 -0.00737716 10.6382 0.00905379C11.788 0.0145308 12.826 0.86894 12.9627 1.88218C12.9814 2.01363 12.9876 2.14508 12.9876 2.27652C13 3.60743 13 4.93834 13 6.26924Z"
                      fill="black"/>
                  </svg>
                  <div class="ms-2" @click="copyLink"> {{ $t('copy-link') }}</div>
                </span>
                            </div>
                            <p class="description" v-html="blog_post.body"></p>

                        </div>
                    </div>
                    <div class="comments-wrapper" v-if="false">
                        <div class="comments-title d-flex align-items-center">
                            <svg width="18" height="17" viewBox="0 0 18 17" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_225_26192)">
                                    <path
                                        d="M4.92289 13.6364L14.4408 13.6668C15.9793 13.6668 17.2306 12.4308 17.2306 10.9111V3.51543C17.2306 1.99576 15.9793 0.759766 14.4408 0.759766H3.55879C2.02033 0.759766 0.769043 1.99576 0.769043 3.51543V10.9213L0.820325 15.9666C0.820325 16.2097 1.09725 16.3313 1.29212 16.1793L4.70751 13.7175C4.76904 13.6668 4.85109 13.6364 4.93315 13.6364H4.92289Z"
                                        stroke="#FF7A00" stroke-width="1.5" stroke-miterlimit="10"
                                        stroke-linecap="round"/>
                                    <path d="M4.19482 5.01465H13.7641" stroke="#FF7A00" stroke-width="1.5"
                                          stroke-miterlimit="10"
                                          stroke-linecap="round"/>
                                    <path d="M4.19482 8.40918H13.7641" stroke="#FF7A00" stroke-width="1.5"
                                          stroke-miterlimit="10"
                                          stroke-linecap="round"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_225_26192">
                                        <rect width="18" height="17" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                            <div class="ms-2"> {{ translation.comments }}</div>
                        </div>
                        <div class="one-comment" v-for="(comment, comment_index) in comments" :key="comment_index">
                            <div class="comment-name-date d-flex">
                                <div class="comment-name"> {{ comment.name }}</div>
                                <div class="comment-date"> {{ comment.date }}</div>
                            </div>
                            <div class="comment-content">
                                {{ comment.content }}
                            </div>
                        </div>
                        <div class="comments-title d-flex align-items-center">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_225_26205)">
                                    <path
                                        d="M9.05116 2.89768H2.78915C2.31464 2.89768 1.85956 3.08618 1.52403 3.42171C1.1885 3.75724 1 4.21231 1 4.68682V17.2109C1 17.6854 1.1885 18.1404 1.52403 18.476C1.85956 18.8115 2.31464 19 2.78915 19H15.3132C15.7877 19 16.2428 18.8115 16.5783 18.476C16.9138 18.1404 17.1023 17.6854 17.1023 17.2109V10.9488M15.7605 1.55582C16.1163 1.19993 16.599 1 17.1023 1C17.6056 1 18.0883 1.19993 18.4442 1.55582C18.8001 1.9117 19 2.39438 19 2.89768C19 3.40097 18.8001 3.88365 18.4442 4.23954L9.94574 12.738L6.36744 13.6326L7.26201 10.0543L15.7605 1.55582Z"
                                        stroke="#FF7A00" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_225_26205">
                                        <rect width="20" height="20" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>

                            <div class="ms-2"> {{ translation.write_a_comment }}</div>
                        </div>
                        <div class="write-a-commment row">
                            <div class="col-md-6">
                                <div class="comment-input-label"> {{ translation.your_name }}</div>
                                <InputField :inputtype="'text'" @update:modelValue="(val) => newComment.name=val"/>
                            </div>
                            <div class="col-md-6">
                                <div class="comment-input-label"> {{ translation.your_email }}</div>
                                <InputField :inputtype="'email'" @update:modelValue="(val) => newComment.email=val"/>
                            </div>
                            <div class="col-md-12">
                                <div class="comment-input-label"> {{ translation.your_message }}</div>
                                <div class="comment-text-area">
                                    <TextArea @update:modelValue="(val) => newComment.content=val"/>
                                </div>

                            </div>
                            <div class="col-md-4 button-publish">
                                <Button @click="postNewComment()" :theme="'solid-orange'" :size="'medium'"
                                        :text="'Публикувай'"/>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-md-4 d-none d-md-block ">
                    <div class="categories-menu-wrapper"
                         v-bind:class="{ 'open-categories-menu-mobile': categoriesMenuActive }">
                        <div class="blog-categories-menu-title d-flex" @click="toggleCategoriesMenu">
                            <div class="me-auto d-none d-md-block"> {{ translation.categories }}</div>
                            <div class="me-auto d-md-none"> {{ selectedCategory.title }}</div>
                            <div class="d-md-none">
                                <svg width="12" height="7" viewBox="0 0 12 7" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <line x1="11.5" y1="0.5" x2="0.5" y2="0.499999" stroke="#1A2128"
                                          stroke-linecap="round"/>
                                    <line x1="11.5" y1="3.5" x2="0.5" y2="3.5" stroke="#1A2128" stroke-linecap="round"/>
                                    <line x1="11.5" y1="6.5" x2="0.5" y2="6.5" stroke="#1A2128" stroke-linecap="round"/>
                                </svg>

                            </div>
                        </div>
                        <div class="categories-menu">
                            <div class="categories-menu-item"
                                 v-for="(category, category_index) in blog_post.tags" :key="category_index">
                                <router-link :to="{name:'BlogCategory', params: {id: category.id}}">
                                    {{ category.title }}
                                </router-link>
                            </div>
                        </div>
                        <div class="blog-categories-menu-title" v-if="products.length > 0">
                            <div class="mb-2">{{ translation.products_connected_with_article }}</div>
                            <div class="blog-products-carousel">
                                <Carousel v-bind="settings">
                                    <Slide v-for="(product, product_index) in products" :key="product_index">
                                        <div class="slider_item">
                                            <MarketplaceAd
                                                :id="product.id"
                                                :labelnew="true /* todo:! */"
                                                :labeldiscount="true"
                                                :title="product.title"
                                                :category="product.categories.length > 0 ? $t(product.categories[0].title) : null"
                                                :regularamount="product.price"
                                                :discountedamount="product.promoPrice"
                                                :image="product.mainImage !== null ? product.mainImage.src : ''"
                                            />
                                        </div>
                                    </Slide>
                                    <template #addons>
                                        <Navigation>
                                            <template #next>
                                                <div class="buttons-left-right button-right">
                                                    <svg width="18" height="14" viewBox="0 0 18 14" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <g clip-path="url(#clip0_1747_9026)">
                                                            <path d="M1 7L17 7" stroke="#434D56" stroke-width="1.5"
                                                                  stroke-linecap="round"/>
                                                            <path d="M11 13L17 7L11 0.999999" stroke="#434D56"
                                                                  stroke-width="1.5" stroke-linecap="round"
                                                                  stroke-linejoin="round"/>
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_1747_9026 ">
                                                                <rect width="18" height="14" fill="white"/>
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                </div>
                                            </template>
                                            <template #prev>
                                                <div class="buttons-left-right button-left">
                                                    <svg width="18" height="14" viewBox="0 0 18 14" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <g clip-path="url(#clip0_1747_16541)">
                                                            <path d="M17 7L1 7" stroke="#434D56" stroke-width="1.5"
                                                                  stroke-linecap="round"/>
                                                            <path d="M7 1L1 7L7 13" stroke="#434D56" stroke-width="1.5"
                                                                  stroke-linecap="round"
                                                                  stroke-linejoin="round"/>
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_1747_16541">
                                                                <rect width="18" height="14" fill="white"
                                                                      transform="translate(18 14) rotate(180)"/>
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                </div>
                                            </template>
                                        </Navigation>
                                    </template>
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row more-posts-from-category" v-if="blog_post">
            <IndexBlogPost :title="translation.more_from_this_category" :category="blog_post.tags[0].id"/>
        </div>
    </div>
</template>

