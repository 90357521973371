import Button from "@/components/Common/Buttons/Button/Button";
import AboutButton from "@/components/Common/Buttons/AboutButton/AboutButton";
import InputField from "@/components/Common/Inputs/Input/Input";
import TextArea from "@/components/Common/Inputs/TextArea/TextArea";
import BlogPostItem from "@/components/Components/BlogPostItem/BlogPostItem/";
import IndexMarketplaceCarousel from "@/views/Index/IndexMarketplaceCarousel/IndexMarketplaceCarousel/";
import IndexBlogPost from "@/views/Index/IndexBlogPost/IndexBlogPost";
import MarketplaceAd from "@/components/Components/MarketplaceAd/MarketplaceAd";
import {Carousel, Navigation, Pagination, Slide} from 'vue3-carousel'
import Footer from "@/components/Footer/Footer";


export default {
    name: 'BlogSinglePostPage',
    components: {
        MarketplaceAd,

        Button,
        InputField,
        TextArea,
        BlogPostItem,
        AboutButton,
        IndexMarketplaceCarousel,
        IndexBlogPost,
        Footer,
        Carousel,
        Slide,
        Pagination,
        Navigation,
    },
    data() {
        return {
            categoriesMenuActive: false,
            selectedCategory: {
                title: 'Категории',
                value: 'price_ascending',
            },
            sortingCategories: [],
            translation: {
                categories: 'Категории',
                architecture: 'Архитектура',
                interior_design: 'Интериорен дизайн',
                roommate_stories: 'Съквартирантски истории',
                interesting_from_city: 'Интересно от града',
                events: 'Събития',
                top_posts: 'Топ публикации',
                last_posts: 'Последни публикации',
                products_connected_with_article: 'Продукти свързани с тази статия',
                comments: 'Коментари',
                write_a_comment: 'Напиши коментар',
                your_name: 'Вашето име',
                your_email: 'Вашият email',
                your_message: 'Вашето съобщение',
                more_from_this_category: 'Още от категорията'
            },
            blog_post: null,
            comments: [],
            products: [],
            newComment: {
                name: '',
                email: '',
                date: '',
                content: ''
            },
            // carousel settings
            settings: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
        }
    },
    methods: {
        toggleCategoriesMenu() {
            this.categoriesMenuActive = !this.categoriesMenuActive
        },
        selectCategory(category) {
            this.toggleCategoriesMenu();
            this.selectedCategory = category;
        },
        postNewComment() {
            this.$store.dispatch('blog/postComment', {id: this.blog_post.id, comment: this.newComment});
            this.comments.push({
                content: this.newComment.content,
                date: new Date().toJSON().slice(0, 10).split('-').reverse().join('.'),
                name: this.newComment.name,
                email: this.newComment.email,
            });
            // reset comment form
            this.newComment.name = '';
            this.newComment.email = '';
            this.newComment.content = '';
        },
        async copyLink() {
            try {
                await navigator.clipboard.writeText(window.location.href);
                alert('Link copied to clipboard!');
            } catch (err) {
                console.error('Failed to copy: ', err);
            }
        }

    },
    async mounted() {
        this.blog_post = await this.$store.dispatch('blog/getPost', this.$route.params.id);
        this.blog_post = await this.$store.dispatch('blog/getPost', this.$route.params.id);
        this.comments = this.blog_post.comments ?? [];
        this.products = this.blog_post.relatedProducts;
    },
}
